// src/components/Layout.js
import React, { useEffect } from 'react';
import $ from 'jquery';


import '../assets/css/plugins/fontawesome-6.css';
import '../assets/css/plugins/swiper.css';
import '../assets/css/vendor/bootstrap.min.css';
import '../assets/css/style.css';

window.$ = $;
window.jQuery = $;
const AuthLayout = ({ children }) => {

        useEffect(() => {
            // Initialize jQuery-dependent plugins or custom scripts here
            if(false) {
            // Ensure jQuery is available globally if main.js requires it
            window.jQuery = $;
            window.$ = $;

            // Dynamically load main.js script
            const script = document.createElement('script');
            script.src = '/assets/js/main.js';
            script.async = true;
            document.body.appendChild(script);

            // Dynamically load swiper.js script
            const swiperScript = document.createElement('script');
            swiperScript.src = '/assets/js/plugins/swiper.js';
            swiperScript.async = true;
            document.body.appendChild(swiperScript);

            // Clean up the dynamically loaded scripts
            return () => {
                document.body.removeChild(script);
                document.body.removeChild(swiperScript);
            };
            }
        }, []); // Empty dependency array ensures this runs only once when the component mounts

    return (
        <div className="dash-board-main-wrapper pt--40">
            <div className="main-center-content-m-left center-content">
                {children}
            </div>
        </div>
    );
};

export default AuthLayout;
