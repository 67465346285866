import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth'; // Custom hook to get the current user
import { db } from '../firebase'; // Adjust the path as needed
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import banner01 from '../assets/images/banner/01.jpg';

const Profile = () => {
    const { currentUser } = useAuth();
    const [name, setName] = useState('');
    const [gender, setGender] = useState('male');
    const [dob, setDob] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchProfile = async () => {
            if (currentUser) {
                const docRef = doc(db, 'users', currentUser.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setName(data.name || '');
                    setGender(data.gender || 'male');
                    setDob(data.dob ? new Date(data.dob.seconds * 1000).toISOString().split('T')[0] : '');
                }
            }
        };
        fetchProfile();
    }, [currentUser]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            if (currentUser) {
                await setDoc(doc(db, 'users', currentUser.uid), {
                    name,
                    gender,
                    dob: new Date(dob),
                });
                toast.success('Profile updated successfully!');
            } else {
                toast.error('User is not authenticated');
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            toast.error('Failed to update profile. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="container-custom">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="user-profile-inner">
                            <h4 className="title">User Profile</h4>
                            <div className="user-profile-main-wrapper">
                                <div className="thumbnail mb-3">
                                    <img src={banner01} alt="images" className="img-fluid"/>
                                </div>
                                <div className="user-profile-content-details">
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-floating mb-3">
                                            <input
                                                id="f-name"
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                disabled={loading}
                                            />
                                            <label htmlFor="f-name">Name</label>
                                        </div>

                                        <div className="mb-3">
                                            <label>Gender</label>
                                            <div>
                                                <input
                                                    type="radio"
                                                    id="male"
                                                    name="gender"
                                                    value="male"
                                                    checked={gender === 'male'}

                                                    onChange={() => setGender('male')}
                                                    disabled={loading}
                                                />
                                                <label htmlFor="male" className="ms-2" style={{paddingLeft:'10px'}}>Male</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="radio"
                                                    id="female"
                                                    name="gender"
                                                    value="female"
                                                    checked={gender === 'female'}
                                                    onChange={() => setGender('female')}

                                                    disabled={loading}
                                                />
                                                <label htmlFor="female" className="ms-2" style={{paddingLeft:'10px'}}>Female</label>
                                            </div>
                                        </div>

                                        <div className="form-floating mb-3">
                                            <input
                                                id="dob"
                                                type="date"
                                                className="form-control"
                                                placeholder="Date of Birth"
                                                value={dob}
                                                onChange={(e) => setDob(e.target.value)}
                                                disabled={loading}
                                            />
                                            <label htmlFor="dob">Date of Birth</label>
                                        </div>

                                        <button type="submit" className="rts-btn btn-primary w-100 mt--20" disabled={loading}>
                                            {loading ? 'Updating...' : 'Update Profile'}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default Profile;
