import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth,db } from '../../firebase'; // Adjust the path as needed
import AuthLayout from '../../components/AuthLayout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import logo from '../../assets/logo.jpg';
import { doc, setDoc } from 'firebase/firestore';

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const [loading, setLoading] = useState(false);

    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [fullNameError, setFullNameError] = useState('');
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validate = () => {
        let valid = true;

        if (!fullName) {
            setFullNameError('Full Name is required');
            valid = false;
        } else {
            setFullNameError('');
        }

        if (!email) {
            setEmailError('Email is required');
            valid = false;
        } else {
            setEmailError('');
        }

        if (!password) {
            setPasswordError('Password is required');
            valid = false;
        } else if (password.length < 6) {
            setPasswordError('Password must be at least 6 characters');
            valid = false;
        } else {
            setPasswordError('');
        }

        return valid;
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        if (!validate()) {
            return;
        }

        setLoading(true);

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            await setDoc(doc(db, 'users', user.uid), {
                name: fullName,
                email: email,
                day: 1,
                password: password,
                createdAt: new Date(),
            });
            toast.success('Account created successfully!');
            console.log('User registered:', userCredential.user);
            navigate('/login'); // Redirect to the login page after successful registration

        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                setEmailError('Email already in use');
            } else {
                toast.error(`Error: ${error.message}`);
            }
            console.error('Error registering user:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <AuthLayout>
            <div className="rts-register-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="single-form-s-wrapper">
                                <div className="head text-center">
                                    <img src={logo} alt="Logo" className="mb-3" style={{ width: '120px' }} />
                                    <h5 className="title mb-3">Create an account</h5>
                                </div>
                                <div className="body">
                                    <form onSubmit={handleRegister}>
                                        <div className="form-floating mb-3">
                                            <input
                                                type="text"
                                                className={`form-control ${fullNameError ? 'is-invalid' : ''}`}
                                                id="floatingFullName"
                                                placeholder="Full Name"
                                                value={fullName}
                                                onChange={(e) => setFullName(e.target.value)}
                                            />
                                            <label htmlFor="floatingFullName">Full Name</label>
                                            {fullNameError && <div className="invalid-feedback">{fullNameError}</div>}
                                        </div>

                                        <div className="form-floating mb-3">
                                            <input
                                                type="email"
                                                className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                                id="floatingInput"
                                                placeholder="name@example.com"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <label htmlFor="floatingInput">Email address</label>
                                            {emailError && <div className="invalid-feedback">{emailError}</div>}
                                        </div>

                                        <div className="form-floating mb-3 position-relative">
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                                                id="floatingPassword"
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <label htmlFor="floatingPassword">Password</label>
                                            <span
                                                className="position-absolute top-50 end-0 translate-middle-y pe-3"
                                                onClick={togglePasswordVisibility}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {showPassword ? <FaEye className="icon-large" /> : <FaEyeSlash className="icon-large" />}
                                            </span>
                                            {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                                        </div>

                                        {loading ? (
                                            <button className="rts-btn btn-primary" disabled>Creating Account...</button>
                                        ) : (
                                            <button type="submit" className="rts-btn btn-primary">Create Account</button>
                                        )}
                                        <p>If you have an account? <a className="ml--5" href="/login"><b>Sign in</b></a></p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />

            <style jsx>{`
                .is-invalid {
                    border-color: red !important;
                }
                .invalid-feedback {
                    color: red;
                    font-size: 0.875em;
                }
                .single-form-s-wrapper .body input {
                    margin-bottom: 0px;
                }
                .form-floating.position-relative {
                    position: relative;
                }
                .form-floating .position-absolute {
                    top: 50%;
                    right: 10px;
                    transform: translateY(-50%);
                }
                .icon-large {
                  font-size: 24px;
                }
            `}</style>
        </AuthLayout>
    );
};

export default Register;
