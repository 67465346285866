// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCR5XCBS8Pl0YpSEta7JgrTi4tVNvOjUSY",
    authDomain: "metamindful.firebaseapp.com",
    projectId: "metamindful",
    storageBucket: "metamindful.appspot.com",
    messagingSenderId: "738813463648",
    appId: "1:738813463648:web:b38cdb888c939e823d8705",
    measurementId: "G-79870088NR"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export const auth = getAuth(app);
