import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase'; // Adjust the path as needed
import AuthLayout from '../../components/AuthLayout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState('');

    const validate = () => {
        let valid = true;

        if (!email) {
            setEmailError('Email is required');
            valid = false;
        } else {
            setEmailError('');
        }

        return valid;
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();

        if (!validate()) {
            return;
        }

        setLoading(true);

        try {
            await sendPasswordResetEmail(auth, email);
            toast.success('Password reset link sent to your email!');
        } catch (error) {
            if (error.code === 'auth/user-not-found') {
                setEmailError('No user found with this email');
            } else {
                toast.error(`Error: ${error.message}`);
            }
            console.error('Error sending password reset email:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <AuthLayout>
            <div className="rts-register-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="single-form-s-wrapper reset text-center">
                                <div className="head mb-4">
                                    <h5 className="title">Reset Your Password</h5>
                                </div>
                                <div className="body">
                                    <form onSubmit={handleResetPassword}>
                                        <div className="form-floating mb-3">
                                            <input
                                                type="email"
                                                className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                                id="floatingInput"
                                                placeholder="name@example.com"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <label htmlFor="floatingInput">Email address</label>
                                            {emailError && <div className="invalid-feedback">{emailError}</div>}
                                        </div>

                                        {loading ? (
                                            <button className="rts-btn btn-primary w-100" disabled>Sending...</button>
                                        ) : (
                                            <button type="submit" className="rts-btn btn-primary w-100">Send Reset Link</button>
                                        )}
                                        <p className="mt-3"><a href="/login"><i className="fa-solid fa-arrow-left"></i> Back to Login</a></p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <style jsx>{`
                .is-invalid {
                    border-color: red !important;
                }
                .invalid-feedback {
                    color: red;
                    font-size: 0.875em;
                }
            `}</style>
        </AuthLayout>
    );
};

export default ResetPassword;
