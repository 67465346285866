import React from 'react';

import banner01 from '../assets/images/banner/01.png';

const Home = () => {
    return (
        <>
            <div className="banner-badge bg_image">
                <div className="inner">
                    <h3 className="title">Meta Mindful</h3>
                    <p className="dsic">
                        Our AI chatbot transforms mindfulness into a tangible skill. Developed by mindfulness coaches, AI professionals, and psychologists, MetaMindful creates an effective personalized learning experience.                    </p>
                    <a href="/" className="rts-btn btn-blur">Try Now</a>
                    <div className="inner-right-iamge">
                        <img src={banner01} alt="banner" />
                    </div>
                </div>
            </div>
            <div className="search__generator mt--50">
                <h4 className="title color-white-title-home">👋 Welcome</h4>
                {/* Additional Home content goes here */}
            </div></>
    );
}

export default Home;
