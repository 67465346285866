import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import homeIcon from '../assets/images/icons/01.png';
import chatIcon from '../assets/images/icons/04.png';

function Sidebar({ sidebarOpen }) {
    const location = useLocation(); // Hook to get the current location

    return (
        <div className={`left-side-bar ${sidebarOpen ? '' : 'collapsed'}`}>
            <div className="overlay-mobile-area"></div>
            <div className="inner">
                <div className="single-menu-wrapper">
                    <Link
                        to="/dashboard"
                        className={`single-menu ${location.pathname === '/dashboard' ? 'active' : ''} openuptip`}
                        flow="right"
                        tooltip="Dashboard"
                    >
                        <div className="icon">
                            <img src={homeIcon} alt="icons" />
                        </div>
                        <p>Home</p>
                    </Link>

                    <Link
                        to="/chat"
                        className={`single-menu ${location.pathname === '/chat' ? 'active' : ''} openuptip`}
                        flow="right"
                        tooltip="Chat"
                    >
                        <div className="icon">
                            <img src={chatIcon} alt="icons" />
                        </div>
                        <p>Chat</p>
                    </Link>

                    <Link
                        to="/daily-chat"
                        className={`single-menu ${location.pathname === '/daily-chat' ? 'active' : ''} openuptip`}
                        flow="right"
                        tooltip="Chat"
                    >
                        <div className="icon">
                            <img src={chatIcon} alt="icons" />
                        </div>
                        <p>Daily Chat</p>
                    </Link>
                    <Link
                        to="/multiple-chat"
                        className={`single-menu ${location.pathname === '/multiple-chat' ? 'active' : ''} openuptip`}
                        flow="right"
                        tooltip="Chat"
                    >
                        <div className="icon">
                            <img src={chatIcon} alt="icons" />
                        </div>
                        <p>Multiple Chat</p>
                    </Link>
                    <Link
                        to="/profile"
                        className={`single-menu ${location.pathname === '/profile' ? 'active' : ''} openuptip`}
                        flow="right"
                        tooltip="Profile"
                    >
                        <div className="icon">
                            <i className="fas fa-user"></i>
                        </div>
                        <p>Profile</p>
                    </Link>
                    <Link
                        to="/change-password"
                        className={`single-menu ${location.pathname === '/change-password' ? 'active' : ''} openuptip`}
                        flow="right"
                        tooltip="Change Password"
                    >
                        <div className="icon">
                            <i className="fas fa-key"></i>
                        </div>
                        <p>Change Password</p>
                    </Link>

                    {/* Other sidebar menu items */}
                </div>
                {/* User profile and upgrade to pro button */}
            </div>


        </div>
    );
}

export default Sidebar;
