import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import logo from '../assets/logo2.png';
import profileUser from '../assets/profile-user.png';
import { useAuth } from '../hooks/useAuth';
import {jsPDF} from "jspdf"; // Custom hook to get the current user


const DailyChat = () => {
    const { currentUser } = useAuth();
    const user_id=currentUser.uid;
    const chatEndRef = useRef(null);
    const [message, setMessage] = useState('');
    const textareaRef = useRef(null);
    const [maxLength, setMaxLength] = useState(150);
    const [systemPrompt, setSystemPrompt] = useState('You Are Metamindful'); // State to hold system prompt

    const [summary, setSummary] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [error, setError] = useState(null);
    const [connectionError, setConnectionError] = useState('');

    const websocket = useRef(null);
    const [retryCount, setRetryCount] = useState(0);
    const maxRetries = 3;
    const [isConnecting, setIsConnecting] = useState(false);
    const lastMessageRef = useRef({ message: '', timestamp: '' }); // Store last message and timestamp



    const initializeWebSocket = () => {
        setRetryCount(prevRetryCount => {
            if (prevRetryCount >= maxRetries) {
                setConnectionError('Failed to connect after 3 attempts.');
                console.log('Max retries reached:', prevRetryCount);

                return prevRetryCount;
            }

            console.log('Retry count before update:', prevRetryCount);

            if (websocket.current && websocket.current.readyState === WebSocket.CONNECTING) {
                return prevRetryCount;
            }
            setIsConnecting(true);

            if (websocket.current) {
                websocket.current.close();
            }
            websocket.current = new WebSocket('wss://localserver.airec.tech:8000/ws');

            websocket.current.onopen = () => {
                console.log("WebSocket opened");
                setConnectionError('');
                setRetryCount(0); // Reset retry count on successful connection
                setIsConnecting(false); // Connection completed
                sendMessage();

            };

            websocket.current.onmessage = (event) => {
                handleReceiveMessage(event);
            };

            websocket.current.onclose = (event) => {
                console.log("WebSocket connection closed", event);
                if (!event.wasClean && prevRetryCount < maxRetries) {
                    console.log('Retrying WebSocket connection:', prevRetryCount + 1);
                    setRetryCount(prev => prev + 1);
                    setTimeout(initializeWebSocket, 100); // Retry after a short delay
                } else {
                    //      setConnectionError('WebSocket connection failed.');
                    setIsConnecting(false);
                }
            };

            websocket.current.onerror = error => {
                console.error("WebSocket error:", error);
                //   setConnectionError('Error connecting to WebSocket.');
                if (prevRetryCount < maxRetries) {
                    console.log('Retry count inside onError:', prevRetryCount + 1);
                    setRetryCount(prev => prev + 1);
                    setTimeout(initializeWebSocket, 100); // Retry after a short delay
                } else {
                    setIsConnecting(false); // Stop retries if the max limit is reached
                }
            };

            return prevRetryCount; // Return the previous retry count
        });
    };


    const downloadPdf = () => {
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: 'a4'
        });

        const margin = 20;
        let cursorY = 40;
        const lineHeight = 18;

        const addMessageToPDF = (message, isUser) => {
            const label = isUser ? "User" : "Assistant";
            const lines = pdf.splitTextToSize(message.content, pdf.internal.pageSize.width - 2 * margin);
            const textHeight = 10;

            lines.forEach((line, index) => {
                if (cursorY + textHeight > pdf.internal.pageSize.height - margin) {
                    pdf.addPage();
                    cursorY = 40;
                }

                pdf.setFillColor(isUser ? 221 : 245, isUser ? 234 : 245, isUser ? 247 : 245);
                pdf.rect(margin, cursorY - textHeight + 2, pdf.internal.pageSize.width - 2 * margin, textHeight + 4, 'F');
                pdf.setTextColor(0, 0, 0);
                if (index === 0) {
                    pdf.text(`${label}:`, margin, cursorY);
                }

                const labelWidth = pdf.getStringUnitWidth(`${label}: `) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
                pdf.setTextColor(isUser ? 30 : 33, isUser ? 136 : 33, isUser ? 229 : 33);
                pdf.text(line, margin + labelWidth, cursorY);

                cursorY += lineHeight;
            });
        };

        chatHistory.forEach(message => {
            addMessageToPDF(message, message.role === 'user');
        });

        pdf.save('chat-history.pdf');
    };


    useEffect(() => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [chatHistory]);

    const handleSystemPromptChange = (e) => {
        setSystemPrompt(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!message.trim()) return;

        const userMessage = {
            role: 'user',
            content: message,
            timestamp: new Date().toISOString(),
        };
        setChatHistory(prev => [...prev, userMessage]);

        sendMessage();

        setMessage('');
        textareaRef.current.style.height = "60px";
    };

    const handleReceiveMessage = (event) => {
        const chunk = event.data;
        const timestamp = new Date().toISOString();
        if (chunk.includes('~~~')) {
            console.log("Received close signal, closing WebSocket...");
            websocket.current.close();
            return;
        }

        // Avoid processing the message if it's the same as the last one and the timestamp is close
        if (lastMessageRef.current.message === chunk && Math.abs(new Date(timestamp) - new Date(lastMessageRef.current.timestamp)) < 1000) {
            return;
        }

        setChatHistory(prev => {
            const lastMessage = prev[prev.length - 1];

            if (lastMessage && lastMessage.role === 'assistant' && !lastMessage.completed) {
                const updatedMessage = {
                    ...lastMessage,
                    content: lastMessage.content + chunk,
                    completed: chunk.includes('~~~'),
                    timestamp: timestamp,
                };
                lastMessageRef.current = { message: updatedMessage.content, timestamp: timestamp };
                return [...prev.slice(0, -1), updatedMessage];
            } else {
                const newMessage = {
                    role: 'assistant',
                    content: chunk,
                    completed: chunk.includes('~~~end'),
                    timestamp: timestamp,
                };
                lastMessageRef.current = { message: newMessage.content, timestamp: timestamp };
                return [...prev, newMessage];
            }
        });
    };
    const handleRangeChange = (event) => {
        setMaxLength(event.target.value);
    };
    const sendMessage = () => {
        setRetryCount(0); // Reset retry count on successful connection

        if (isConnecting || !websocket.current || websocket.current.readyState !== WebSocket.OPEN) {
            initializeWebSocket();
            return; // Only attempt to send the message after WebSocket is open
        }
        const promptToSend = systemPrompt.trim() ? systemPrompt : 'You are metamindful'; // Use default if prompt is empty

        const prompt = {
            "role": 'system',
            "content":promptToSend
        };
        const updatedChatHistory = [prompt, ...chatHistory];


        const userPrompt = {
            "role": 'user',
            "content":message
        };
        const updatedUserChatHistory = [...updatedChatHistory,userPrompt];

        const dataToSend = {
         //   "ques": message,
           // "systemPrompt":promptToSend,
            "messageTime": new Date().toISOString(),
            "ID": user_id,
            "conversation":updatedUserChatHistory,
            "max_token":maxLength,
       //     "delete":false,
        };
        websocket.current.send(JSON.stringify(dataToSend));
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    const handleInputChange = (e) => {
        setMessage(e.target.value);
        textareaRef.current.style.height = "auto";
        const scrollHeight = textareaRef.current.scrollHeight;
        const height = Math.min(scrollHeight, 10 * 30);
        textareaRef.current.style.height = height < 60 ? `60px` : `${height}px`;
    };

    return (
        <>
            <div className="chat__container">
                <div className="chat__wrapper">
                    <div className="header__section">
                        <input
                            type="text"
                            placeholder="System Prompt"
                            className="form-control prompt"
                            value={systemPrompt}
                            onChange={handleSystemPromptChange}
                        />
                        <button className="btn btn-info pdf-btn"  onClick={downloadPdf}>Download PDF</button>

                    </div>
                    <div>
                        <label htmlFor="customRange" className="form-label">Max Length: {maxLength}</label>
                        <input
                            type="range"
                            className="form-range"
                            id="customRange"
                            min="0"
                            max="1000"
                            value={maxLength}
                            onChange={handleRangeChange}
                        />
                    </div>
                    {error ? (
                        <div className="error">{error}</div>
                    ) : connectionError ? (
                        <div className="error">{connectionError}</div>
                    ) : (
                        <>
                            <div className="question_answer__wrapper__chatbot">
                                {chatHistory.map((message, i) => (
                                    <div className="single__question__answer" key={i}>
                                        {message.role === 'user' && (
                                            <div className='question_user'>
                                                <div className="left_user_info">
                                                    <img style={{ width: '35px' }} src={profileUser} alt="avatar" />
                                                    <div className="question__user">
                                                        You: {message.content}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {message.role === 'assistant' && (
                                            <div className="answer__area">
                                                <div className="thumbnail">
                                                    <img style={{ width: '40px !important' }} src={logo} alt="avatar" />
                                                </div>
                                                <div className="answer_main__wrapper" style={{ background: '#E2FFC7', padding: '10px', marginTop: '0px', borderRadius: '10px' }}>
                                                    <h4 className="common__title">Assistant</h4>
                                                    <p className="disc">
                                                        {message.content}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div ref={chatEndRef}></div>
                                { summary.trim() &&  ( <div className="summary__section">
                                        <h3>Summary:</h3>
                                        <p>{summary}</p>
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                    <form onSubmit={handleSubmit} className="search-form">
                        <div className="mb-3" style={{ position: 'relative' }}>
                            <textarea
                                ref={textareaRef}
                                className="form-control"
                                style={{
                                    borderRadius: '20px',
                                    background: '#E2FFC7',
                                    width: '100%',
                                    paddingRight: '40px',
                                    resize: 'none',
                                    overflow: 'hidden',
                                    height: '60px',
                                }}
                                id="messageTextarea"
                                placeholder="Ask here"
                                rows="1"
                                value={message}
                                onChange={handleInputChange}
                                onKeyPress={handleKeyPress}
                            ></textarea>
                            <button
                                type="submit"
                                className="send-button"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    width: '40px',
                                    right: '10px',
                                    transform: 'translateY(-50%)',
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    cursor: 'pointer',
                                    fontSize: '2.5rem',
                                    color: message.trim() ? 'black' : '#D7D7D7',
                                }}
                                disabled={!message.trim()}
                            >
                                <i className="fa fa-paper-plane" aria-hidden="true"></i>
                            </button>
                        </div>
                    </form>
                </div>

                <style jsx>{`
                    .chat__container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      /*   height: 100vh;*/
                    }

                    .chat__wrapper {
                        width: 800px;
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        background-color: #fff;
                        border-radius: 8px;
                    }

                    .header__section {
                        padding: 10px;
                        display: flex;
                        justify-content: flex-start;
                        background-color: #f9f9f9;
                        border-bottom: 1px solid #ccc;
                    }

                    .prompt {
                        font-size: 1.7rem;
                        padding: 5px;
                        font-weight:bold !important;
                        width:75% !important;
                        border-radius: 5px !important;
                        border: 1px solid #ced4da !important;
                        outline: none;
                    }
                    
                    .pdf-btn {
                        font-size: 1.7rem;
                        padding: 5px;
                        font-weight:bold;
                        width:20%;
                        border-radius: 5px;
                        border: 1px solid #ccc;
                        outline: none;
                    }

                    .summary__section {
                        padding: 20px;
                        border-bottom: 1px solid #ccc;
                        background-color: #f9f9f9;
                    }

                    .question_answer__wrapper__chatbot {
                        flex: 1;
                        overflow-y: auto;
                        padding: 20px;
                    }

                    .search-form {
                        padding: 10px;
                        background-color: #fff;
                        border-top: 1px solid #ccc;
                    }

                    .form-control {
                        padding-top: 20px;
                        font-size: 2rem;
                        line-height: 2rem;
                    }

                    .send-button:hover {
                        color: ${message.trim() ? '#0056b3' : '#D7D7D7'};
                    }

                    .assistant {
                        color: blue;
                    }

                    .user {
                        color: green;
                    }

                    .loading {
                        text-align: center;
                        padding: 20px;
                        font-size: 1.5rem;
                        color: #555;
                    }

                    .error {
                        text-align: center;
                        padding: 20px;
                        font-size: 1.5rem;
                        color: red;
                    }
                    .thumbnail{
                        border:2px solid #dfdfdf;
                        border-radius:50%;
                    }
                `}</style>
            </div>
        </>
    );
};

export default DailyChat;
