import React, { useState } from 'react';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase'; // Adjust the path as needed

import logo01 from '../assets/logo2.png';
import meta from '../assets/images/logo/meta.png';
import svg01 from '../assets/images/icons/01.svg';

import avatar01 from '../assets/images/avatar/01.png';
import user01 from '../assets/images/avatar/user-2.svg';

function Header({ setsidebarOpen }) {
    const [openMenu, setopenMenu] = useState(false);
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/login');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <div className="header-area-one">
            <div className="container-30">
                <div className="col-lg-12">
                    <div className="header-inner-one">
                        <div className="left-logo-area">
                            <a href="/" className="logo" style={{'fontSize': '28px', 'fontWeight': 'bold'}}>
                                MetaMindful
                            </a>
                            <div className="left-side-open-clouse" id="collups-left">
                                <img src={svg01} alt="icons" onClick={() => setsidebarOpen(prev => !prev)} />
                            </div>
                        </div>
                        <div className="header-right">
                            <div className="action-interactive-area__header">
                                <div className="single_action__haeader user_avatar__information openuptip" flow="down" tooltip="Profile">
                                    <div className="avatar" onClick={() => setopenMenu(prev => !prev)}>
                                        <img src={logo01} alt="avatar" />
                                    </div>
                                    <div className={`user_information_main_wrapper slide-down__click ${openMenu ? 'd-block' : 'd-none'}`}>


                                        <div className="popup-footer-btn">
                                            <button onClick={handleLogout} className="geex-content__header__popup__footer__link">
                                                Logout <i className="fa-light fa-arrow-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Other header components like search, notification, language, and profile would go here */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
