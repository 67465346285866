// src/components/PublicRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const PublicRoute = ({ children }) => {
    const { currentUser } = useAuth();

    return currentUser ? <Navigate to="/dashboard" /> : children;
};

export default PublicRoute;
