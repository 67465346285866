// src/components/Layout.js
import React, { useState } from 'react';
import $ from 'jquery';



import '../assets/css/plugins/fontawesome-6.css';
import '../assets/css/plugins/swiper.css';
import '../assets/css/vendor/bootstrap.min.css';
import '../assets/css/style.css';

// import '../assets/js/plugins/jquery.min.js';
// import '../assets/js/plugins/swiper.js';
// import '../assets/js/plugins/bootstrap.min.js';
// import '../assets/js/main.js';

import Header from '../includes/Header';
import Sidebar from '../includes/Sidebar';
import Footer from '../includes/Footer';
import {  Outlet } from 'react-router-dom';
window.$ = $;
window.jQuery = $;
const AdminLayout = ({ children }) => {
    const [sidebarOpen, setsidebarOpen] = useState(true)

    return (
        <div className="admin-layout">
            <Header setsidebarOpen={setsidebarOpen} />
            <div className="dash-board-main-wrapper" style={{ marginTop: '120px' }}>
                <Sidebar sidebarOpen={sidebarOpen}/>
                <div className="main-center-content-m-left">
                    <Outlet/>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AdminLayout;
