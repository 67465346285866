import React from 'react';

function Footer() {
    return (
        <div className="copyright-area-bottom">
            <p><a href="https://www.metamindful.ai/">MetaMindful©</a> 2024. All Rights Reserved.</p>
        </div>
    );
}

export default Footer;
