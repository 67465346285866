import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { updatePassword } from 'firebase/auth';
import { useAuth } from '../hooks/useAuth'; // Custom hook to get the current user
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Password = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false); // Loading state to show loader
    const { currentUser } = useAuth(); // Get the current authenticated user

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const validateForm = () => {
        const errors = {};
        if (password.length < 6) {
            errors.password = 'Password must be at least 6 characters long';
        }
        if (password !== confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true); // Start loader
            try {
                if (currentUser) {
                    await updatePassword(currentUser, password);
                    toast.success('Password updated successfully!');
                    setPassword(''); // Clear the password fields
                    setConfirmPassword('');
                } else {
                    toast.error('User is not authenticated');
                }
            } catch (error) {
                console.error('Error updating password:', error);
                toast.error('Failed to update password. Please try again.');
            } finally {
                setLoading(false); // Stop loader
            }
        }
    };

    return (
        <div className="container-custom">
            <div className="row">
                <div className="col-lg-12">
                    <div className="user-profile-inner">
                        <h4 className="title">Update Password</h4>
                        <div className="user-profile-main-wrapper">
                            <div className="user-profile-content-details">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-floating mb-3 position-relative">
                                        <input
                                            id="password"
                                            type={passwordVisible ? "text" : "password"}
                                            className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                            placeholder="Password"
                                            aria-label="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            disabled={loading} // Disable input while loading
                                        />
                                        <label htmlFor="password">Password</label>
                                        <span
                                            className="position-absolute top-50 end-0 translate-middle-y pe-3"
                                            onClick={togglePasswordVisibility}
                                            style={{ cursor: 'pointer' }}
                                        >
                                                {passwordVisible ? <FaEye className="icon-large" /> : <FaEyeSlash className="icon-large" />}
                                        </span>
                                        {errors.password && <small className="text-danger">{errors.password}</small>}
                                    </div>

                                    <div className="form-floating mb-3 position-relative">
                                        <input
                                            id="confirm-password"
                                            type={passwordVisible ? "text" : "password"}
                                            className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                                            placeholder="Confirm Password"
                                            aria-label="Confirm Password"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            disabled={loading} // Disable input while loading
                                        />
                                        <label htmlFor="confirm-password">Confirm Password</label>
                                        <span
                                            className="position-absolute top-50 end-0 translate-middle-y pe-3"
                                            onClick={togglePasswordVisibility}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {passwordVisible ? <FaEye className="icon-large" /> : <FaEyeSlash className="icon-large" />}

                                        </span>
                                        {errors.confirmPassword && <small className="text-danger">{errors.confirmPassword}</small>}
                                    </div>

                                    <button type="submit" className="rts-btn btn-primary w-100 mt--20" disabled={loading}>
                                        {loading ? 'Updating...' : 'Update Password'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <style jsx>{`
                .is-invalid {
                    border-color: red !important;
                }
                .invalid-feedback {
                    color: red;
                    font-size: 0.875em;
                }
                .form-floating.position-relative {
                    position: relative;
                }
                .form-floating .position-absolute {
                    top: 50%;
                    right: 10px;
                    transform: translateY(-50%);
                }
                
                .icon-large {
                  font-size: 24px;
                }
            `}</style>
        </div>
    );
};

export default Password;
